import { Controller } from 'stimulus'
import Turbolinks from 'turbolinks'

export default class extends Controller {
  static targets = [];

  connect () {}

  disconnect () {}

  change (e) {
    const url = e.target.value
    Turbolinks.visit(url)
  }
}
