import { Controller } from 'stimulus';

import numberToHumanSize from './numberToHumanSize';

export default class extends Controller {
  static targets = [
    'root',
    'input',
    'cache',
    'filename',
    'filesize',
    'preview',
    'remove',
    'button',
  ];

  connect() {
    this.sync();
  }

  sync() {
    if (this.required) {
      this.rootTarget.classList.add(this.state('required'));
    } else {
      this.rootTarget.classList.remove(this.state('required'));
    }

    if (this.removed) {
      this.rootTarget.classList.add(this.state('removed'));
      this.inputTarget.disabled = true;
      Array.from(this.buttonTargets, btn => {
        btn.classList.add('disabled');
      });
    } else {
      this.rootTarget.classList.remove(this.state('removed'));
      this.inputTarget.disabled = false;
      Array.from(this.buttonTargets, btn => {
        btn.classList.remove('disabled');
      });
    }

    if (this.inputTarget.value || this.cache) {
      this.beChanged();
      this.updatePreview();
    } else if (this.present) {
      this.beNotChanged();
      this.updatePreview();
      this.bePresent();
    } else {
      this.beNotChanged();
      this.beNotPresent();
    }
  }

  onChange() {
    if (this.hasCacheTarget) {
      this.cacheTarget.disabled = true;
    }
    this.changed = !!this.inputTarget.value;

    this.sync();
  }

  cancel() {
    if (this.inputTarget.value) {
      this.inputTarget.value = null;
    }

    if (this.hasCacheTarget) {
      this.cacheTarget.disabled = true;
    }
    this.changed = false;

    this.sync();
  }

  bePresent() {
    this.rootTarget.classList.add(this.state('present'));
    this.inputTarget.removeAttribute('required');
  }

  beNotPresent() {
    this.rootTarget.classList.remove(this.state('present'));

    if (this.required) {
      this.inputTarget.setAttribute('required', 'required');
    }
  }

  beChanged() {
    this.rootTarget.classList.add(this.state('changed'));
  }

  beNotChanged() {
    this.rootTarget.classList.remove(this.state('changed'));
  }

  updatePreview() {
    const preview = {};
    if (this.inputTarget.files.length) {
      const file = this.inputTarget.files[0];
      preview.filename = file.name;
      preview.filesize = file.size;
      preview.preview = createPreviewElementFromFile(file);
    } else if (this.cache) {
      preview.filename = this.cache.filename;
      preview.filesize = this.cache.byte_size;
      preview.preview = createPreviewElementFromCache(this.cache);
    } else {
      preview.filename = '';
      preview.filesize = '';
      preview.preview = false;
    }

    if (this.hasFilenameTarget) {
      this.filenameTarget.textContent = preview.filename;
    }
    if (this.hasFilesizeTarget) {
      this.filesizeTarget.textContent = numberToHumanSize(preview.filesize);
    }
    if (this.hasPreviewTarget) {
      this.previewTarget.textContent = '';
      if (preview.preview) {
        this.previewTarget.appendChild(preview.preview);
      }
    }
  }

  state(state) {
    return `attachment-upload--${state}`;
  }

  component(component) {
    return `attachment-upload__${component}`;
  }

  get required() {
    return this.data.has('required');
  }

  get present() {
    return this.data.has('present');
  }

  get changed() {
    return this.data.has('changed');
  }

  set changed(value) {
    if (value) {
      this.data.set('changed', true);
    } else {
      this.data.delete('changed');
    }
  }

  get removed() {
    return this.removeTarget.checked;
  }

  get cache() {
    if (this.data.has('cache')) {
      return JSON.parse(this.data.get('cache'));
    }
  }
}

function createPreviewElementFromFile(file) {
  if (/^image\//.test(file.type)) {
    const image = document.createElement('img');
    image.src = URL.createObjectURL(file);
    return image;
  } else if (/^video\//.test(file.type)) {
    const video = document.createElement('video');
    video.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = URL.createObjectURL(file);
    video.appendChild(source);

    return video;
  } else if (/^audio\//.test(file.type)) {
    const audio = document.createElement('audio');
    audio.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = URL.createObjectURL(file);
    audio.appendChild(source);

    return audio;
  }

  return document.createTextElement(file.name);
}

function createPreviewElementFromCache(cache) {
  if (/^image\//.test(cache.type)) {
    const image = document.createElement('img');
    image.src = cache.url;
    return image;
  } else if (/^video\//.test(cache.type)) {
    const video = document.createElement('video');
    video.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = cache.url;
    video.appendChild(source);

    return video;
  } else if (/^audio\//.test(cache.type)) {
    const audio = document.createElement('audio');
    audio.setAttribute('controls', true);

    const source = document.createElement('source');
    source.src = cache.url;
    audio.appendChild(source);

    return audio;
  }

  return document.createTextElement(cache.filename);
}
