import { Controller } from 'stimulus';

import Vue from 'vue/dist/vue.esm';

import numberToHumanSize from './numberToHumanSize';

export default class extends Controller {
  static targets = ['root', 'input', 'filelist'];

  connect() {
    this.vm = new Vue({
      el: this.filelistTarget,
      data: {
        files: [],
      },
      methods: {
        dispose: function() {
          this.$destroy();
        },
        filename: function(file) {
          return file.name;
        },
        filesize: function(file) {
          return numberToHumanSize(file.size);
        },
        audio: function(file) {
          return /^audio\//.test(file.type);
        },
        video: function(file) {
          return /^video\//.test(file.type);
        },
        image: function(file) {
          return /^image\//.test(file.type);
        },
        url: function(file) {
          return URL.createObjectURL(file);
        },
      },
    });

    this.sync();
  }

  disconnect() {
    if (this.vm) {
      this.vm.dispose();
    }
  }

  sync() {
    if (this.required) {
      this.rootTarget.classList.add(this.state('required'));
    } else {
      this.rootTarget.classList.remove(this.state('required'));
    }

    if (this.inputTarget.value) {
      this.beChanged();
      this.updatePreview();
      this.bePresent();
    } else if (this.changed) {
      this.beChanged();
      this.updatePreview();
    } else if (this.present) {
      this.beNotChanged();
      this.updatePreview();
      this.bePresent();
    } else {
      this.beNotChanged();
      this.beNotPresent();
    }
  }

  onChange() {
    this.sync();
  }

  cancel() {
    if (this.inputTarget.value) {
      this.inputTarget.value = null;
    }

    if (this.hasChangedTarget) {
      this.changedTarget.disabled = true;
    }
    this.changed = false;

    this.sync();
  }

  bePresent() {
    this.rootTarget.classList.add(this.state('present'));
    this.inputTarget.removeAttribute('required');
  }

  beNotPresent() {
    this.rootTarget.classList.remove(this.state('present'));

    if (this.required) {
      this.inputTarget.setAttribute('required', 'required');
    }
  }

  beChanged() {
    this.rootTarget.classList.add(this.state('changed'));
  }

  beNotChanged() {
    this.rootTarget.classList.remove(this.state('changed'));
  }

  updatePreview() {
    const files = [];
    Array.from(this.inputTarget.files, file => {
      files.push(file);
    });

    this.vm.files = files;
  }

  state(state) {
    return `attachment-upload--${state}`;
  }

  component(component) {
    return `attachment-upload__${component}`;
  }

  get required() {
    return this.data.has('required');
  }

  get present() {
    return this.data.has('present');
  }

  set present(value) {
    if (value) {
      this.data.set('present', true);
    } else {
      this.data.delete('present');
    }
  }

  get changed() {
    return this.data.has('changed');
  }

  set changed(value) {
    if (value) {
      this.data.set('changed', true);
    } else {
      this.data.delete('changed');
    }
  }
}
