import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['insertionPoint', 'template'];

  add (event) {
    event.preventDefault()
    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    )
    this.insertionPointTarget.insertAdjacentHTML('beforebegin', content)
    const inserted = this.insertionPointTarget.previousElementSibling
    console.info(inserted)
    if (inserted) {
      inserted.classList.add('dynamic')
    }
  }

  remove (event) {
    event.preventDefault()
    const item = event.target.closest('.nested-fields')

    const fields = item.querySelectorAll(
      'input[name], select[name], textarea[name]'
    )
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].name.indexOf('[id]') >= 0) {
        continue
      } else if (fields[i].name.indexOf('[_destroy]') >= 0) {
        fields[i].value = 1
      } else {
        fields[i].disabled = true
      }
    }

    item.classList.add('destroyed')
    item.style.display = 'none'

    if (item.classList.contains('dynamic') && item.parentNode) {
      item.parentNode.removeChild(item)
    }
  }
}
