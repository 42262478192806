import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = [];

  connect () {
    const options = this.data.has('options')
      ? JSON.parse(this.data.get('options'))
      : {}

    this.sortable = Sortable.create(
      this.element,
      Object.assign(options, { onUpdate: this.onUpdate })
    )
  }

  disconnect () {
    this.sortable.destroy()
  }

  onUpdate (evt) {
    const to = evt.to.querySelectorAll('[name*="[position]"]:enabled')
    for (let i = 0; i < to.length; i++) {
      to[i].value = i + 1
    }
  }
}
