import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['state', 'open', 'startTime'];

  connect () {}

  disconnect () {}

  changeState (e) {
    const state = e.target.value
    const wday = e.target.dataset.wday
    Array.from(this.stateTargets, stateTarget => {
      if (stateTarget.dataset.wday === wday) {
        stateTarget.value = state
      }
    })
  }

  changeOpen (e) {
    const open = !!e.target.dataset.open
    const wday = e.target.dataset.wday
    Array.from(this.openTargets, openTarget => {
      if (openTarget.dataset.wday === wday) {
        openTarget.checked = open
      }
    })
  }

  changeStartTime (e) {
    const value = e.target.value
    if (!value) {
      return
    }

    const target = e.target.classList.contains('hour') ? 'hour' : 'minute'

    const wday = e.target.dataset.wday
    Array.from(this.startTimeTargets, startTimeTarget => {
      if (
        startTimeTarget.dataset.wday === wday &&
        startTimeTarget.classList.contains(target)
      ) {
        startTimeTarget.value = value
      }
    })
  }
}
