import { Controller } from 'stimulus'

import $ from 'jquery'

import brace from 'brace'
import 'brace/snippets/liquid'
import 'brace/theme/chrome'
import 'brace/mode/liquid'
import 'brace/ext/searchbox'
import 'brace/ext/language_tools'

export default class extends Controller {
  static targets = ['body', 'bodyEditor', 'form', 'previewParams'];

  connect () {
    if (this.hasBodyTarget && this.hasBodyEditorTarget) {
      const textarea = this.bodyTarget
      const editor = brace.edit(this.bodyEditorTarget)
      editor.setTheme('ace/theme/chrome')
      editor.getSession().setMode('ace/mode/liquid')
      editor.setFontSize(16)
      editor.$blockScrolling = Infinity
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true
      })
      editor.setValue(textarea.value, -1)
      editor
        .getSession()
        .getUndoManager()
        .reset()
      editor.on('change', () => {
        textarea.value = editor.getValue()
      })

      this.editor = editor
    }
  }

  disconnect () {
    if (this.editor) {
      this.editor.destroy()
      this.editor = null
    }
  }

  preview (e) {
    e.preventDefault()
    const url = e.target.dataset.url
    const body = this.bodyTarget.value
    const previewParams = this.previewParamsTarget.value

    const w = window.open(
      '',
      'preview',
      'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes'
    )
    $.post(url + '?' + previewParams, { body }).done(result => {
      w.document.body.innerHTML = result
    })
  }
}
