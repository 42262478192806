import { Controller } from 'stimulus'
import * as $ from 'jquery'
import { fetch } from 'whatwg-fetch'

import './content_loader_controller.scss'

export default class extends Controller {
  static targets = [
    'spinner',
    'target'
  ];

  initialize () {
    this.loadContent = this.loadContent.bind(this)
  }

  connect () {
    if (this.trigger) {
      $(this.element).on(this.trigger, this.loadContent)
    }
  }

  disconnect () {
    if (this.trigger) {
      $(this.element).off(this.trigger, this.loadContent)
    }
  }

  load (e) {
    e.preventDefault()
    this.loadContent()
  }

  loadContent () {
    this.showSpinner()

    fetch(this.url)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.text()
        } else {
          const error = new Error(response.statusText)
          error.response = response
          throw error
        }
      })
      .then(data => {
        this.targetTarget.innerHTML = data
      }).catch(error => {
        alert(error)
      }).finally(() => {
        this.hideSpinner()
      })
  }

  hideSpinner () {
    this.element.classList.remove('content-loader--loading')
    if (this.hasSpinnerTarget) {
      $(this.spinnerTarget).hide()
    }
  }

  showSpinner () {
    this.element.classList.add('content-loader--loading')
    if (this.hasSpinnerTarget) {
      $(this.spinnerTarget).show()
    }
  }

  get trigger () {
    return this.data.get('trigger')
  }

  get url () {
    return this.data.get('url')
  }
}
